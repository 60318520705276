@import './sanitize.scss';
@import './common/StyleVariables/variables.scss';

#banner-container,
#cookies-management-modal {
  word-break: break-word;
  background: white;
}

.sicredi-modal-container {
  z-index: 9999 !important;
}

#cookies-management-modal {
  width: 1000px;

  ::before,
  ::after {
    text-decoration: inherit;
    vertical-align: inherit;
  }

  div:nth-child(1) {
    padding: 0 !important;
    margin: 0 !important;

    h2:nth-child(1) {
      padding: $baseline * 2 !important;
      line-height: normal !important;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      &:nth-child(2) {
        transition: none;
        scroll-behavior: smooth;
        max-height: 100%;
      }
    }

    &:nth-child(2) {
      transition: 0.5ms ease-in-out;
      scroll-behavior: smooth;
      max-height: 100%;
    }
  }

  .management-modal {
    h1:nth-child(1) {
      margin: 0 !important;
      padding: $baseline $baseline * 2;
      line-height: normal;
    }

    .cookies-container:nth-child(2) {
      .cookies-wrapper {
        .strictly-necessary-content,
        .cookies-switch-content {
          margin-bottom: $baseline !important;

          ul li h3 {
            line-height: normal !important;
          }

          ul li h3 button {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 412px) {
    .management-modal {
      .cookies-container:nth-child(2) {
        padding: 0;
        margin: 0;
      }

      h1:nth-child(1) {
        padding: 0;
        margin: 0;
        padding-bottom: $baseline;
      }
    }
  }
}
