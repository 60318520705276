/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */

// colors by dsm: https://sicredi.invisionapp.com/dsm/sicredi/sicredi-library-app/asset/colors/5c5db1000649b2760fc12d02
$bilbao: #33820d;
$atlantis: #3fa110;
$kidnapper: #d7e6c8;
$red: #e60000;
$appleBlossom: #ab4745;
$sundown: #ffb4b4;
$lunarGreen: #323c32;
$finlandia: #5a645a;
$stack: #828a82;
$pumice: #cdd3cd;
$grayNurse: #f8f9f7;
$white: #fff;
$olive: #765f00;
$supernova: #ffcd00;
$picasso: #ffeb98;
$black: #000;

// other colors
$doveGray: #6f6f6f;
$grayNurse2: #e4e4e4;
$grayNurse3: #dbe1db;
$grayNurse4: #f9faf9;
$pumice2: #acb2ac;
$red2: #ca4a4a;
$fantasy: #f9ecec;
$webOrange: #e6a500;
$salomie: #ffeb8c;

/* stylelint-disable */
:export {
  bilbao: $bilbao;
  appleBlossom: $appleBlossom;
  atlantis: $atlantis;
  black: $black;
  doveGray: $doveGray;
  finlandia: $finlandia;
  grayNurse: $grayNurse;
  grayNurse2: $grayNurse2;
  grayNurse3: $grayNurse3;
  grayNurse4: $grayNurse4;
  kidnapper: $kidnapper;
  lunarGreen: $lunarGreen;
  olive: $olive;
  pumice: $pumice;
  red: $red;
  red2: $red2;
  stack: $stack;
  sundown: $sundown;
  fantasy: $fantasy;
  webOrange: $webOrange;
  salomie: $salomie;
  supernova: $supernova;
  white: $white;
  picasso: $picasso;
}
