@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,500,500i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Exo+2:200,200i,300,300i,400,400i,500,500i,700,700i');

$fontFamilyBase: 'Nunito', sans-serif !important;
$fontFamilyHighlighted: 'Exo 2', 'Exo-2', sans-serif !important;

$typographySizeXSmall: 12px !default;
$typographySizeSmall: 14px !default;
$typographySizeMedium: 16px !default;
$typographySizeLarge: 20px !default;
$typographySizeXLarge: 24px !default;
$typographySizeXXLarge: 32px !default;
$typographySizeXXXLarge: 40px !default;

$typographyWeightExtraLight: 200 !default;
$typographyWeightLight: 300 !default;
$typographyWeightNormal: 400 !default;
$typographyWeightSemiBold: 500 !default;
$typographyWeightBold: 700 !default;

/* stylelint-disable */
:export {
  fontFamilyBase: $fontFamilyBase;
  fontFamilyHighlighted: $fontFamilyHighlighted;
  typographySizeXSmall: $typographySizeXSmall;
  typographySizeSmall: $typographySizeSmall;
  typographySizeMedium: $typographySizeMedium;
  typographySizeLarge: $typographySizeLarge;
  typographySizeXLarge: $typographySizeXLarge;
  typographySizeXXLarge: $typographySizeXXLarge;
  typographySizeXXXLarge: $typographySizeXXXLarge;
  typographyWeightExtraLight: $typographyWeightExtraLight;
  typographyWeightLight: $typographyWeightLight;
  typographyWeightNormal: $typographyWeightNormal;
  typographyWeightSemiBold: $typographyWeightSemiBold;
  typographyWeightBold: $typographyWeightBold;
}
