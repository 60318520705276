@import '../../common/StyleVariables/variables.scss';
@import '../../global.scss';

#cookies-management-modal {
  .img-content {
    .sicredi-logo-modal {
      width: $baseline * 16;
    }

    .sicredi-modal-container {
      background-color: yellowgreen;
    }
  }

  .management-modal {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: $baseline * 2;

    .cookies-title {
      word-break: normal;
      font-family: $fontFamilyHighlighted;
    }

    .cookies-container {
      padding: $baseline $baseline * 2;

      .management-modal-paragraph {
        font-family: $fontFamilyBase;
        line-height: initial;
      }

      .cookies-wrapper {
        display: grid;
        grid-template-columns: 1fr minmax(46px, 61px);

        .strictly-necessary-content {
          grid-column: 1;
          margin-bottom: $baseline * 2;

          ul li h3 button {
            padding: $baseline !important;
            font-family: $fontFamilyHighlighted;
          }
        }

        .cookies-switch-content {
          display: flex;
          grid-column: 1/3;
          gap: 15px;
          align-items: center;
          margin-bottom: $baseline * 2;

          ul li h3 button {
            padding: $baseline !important;
            font-family: $fontFamilyHighlighted;
          }

          .switch {
            margin: 0 auto;
          }
        }
      }

      .button-content {
        display: grid;
        grid-template-columns: 1fr 1fr minmax(46px, 61px);
        padding-top: $baseline * 2;

        @media screen and (max-width: 800px) {
          grid-template-columns: 1fr 1fr;
        }

        .save-config-button {
          margin-left: $baseline;
          background-color: $white;
        }

        .reject-config-button {
          margin-right: $baseline;
          background-color: $white;
        }

        .save-config-button:hover,
        .reject-config-button:hover,
        .save-config-button:focus,
        .reject-config-button:focus {
          background: $atlantis !important;
          color: $white;
          border: $atlantis;
        }
      }
    }

    @media only screen and (min-width: 850px) {
      .cookies-title {
        font-size: $typographySizeXXLarge !important;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .img-content {
      padding-left: $baseline * 2;
      .sicredi-logo-modal {
        width: $baseline * 12;
      }
    }

    .management-modal {
      position: relative;
      .cookies-title {
        width: 100%;
        grid-column: 1/3;
        margin: auto;
        margin-left: auto;
        font-size: $typographySizeXLarge !important;
        padding-right: $baseline * 4;
      }

      .cookies-container {
        @media screen and (max-width: 475px) {
          .button-content {
            .save-config-button,
            .reject-config-button {
              font-size: $typographySizeSmall !important;
            }
          }
        }

        grid-column: 1/3;
        margin: 0px;

        .management-modal-paragraph {
          text-align: justify;
          width: 100%;
        }

        .button-content {
          width: 100%;
          position: sticky;
          bottom: 1%;
        }
      }
    }
  }

  @media only screen and (max-width: 412px) {
    .img-content {
      padding-left: $baseline;
    }

    .management-modal {
      position: relative;

      .cookies-title {
        font-size: $typographySizeLarge !important;
        padding-bottom: $baseline;
      }

      .cookies-container {
        .management-modal-paragraph,
        .all-cookies-switch h3 {
          font-size: $typographySizeSmall !important;
        }

        .strictly-necessary-content,
        .cookies-switch-content {
          ul li h3 {
            font-size: $typographySizeSmall !important;
            margin-right: $baseline;
          }
        }

        .button-content {
          width: 100%;
          position: sticky;

          .save-config-button {
            margin-top: $baseline;
          }

          .save-config-button,
          .reject-config-button {
            grid-column: 1/3;
            margin-right: 0px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}
