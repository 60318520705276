$responsiveXSm: 320px;
$responsiveSm: 576px;
$responsiveMd: 768px;
$responsiveLg: 1024px;
$responsiveXLg: 1366px;

/* stylelint-disable */
:export {
  responsiveXSm: $responsiveXSm;
  responsiveSm: $responsiveSm;
  responsiveMd: $responsiveMd;
  responsiveLg: $responsiveLg;
  responsiveXLg: $responsiveXLg;
}
