@import './colors.scss';
@import './typography.scss';
@import './animation.scss';
@import './responsive.scss';

$borderRadius: 4px;
$baseline: 8px;

$z-index: (
  overlay: 10,
  modal: 20,
  select: 30,
  tooltip: 30,
  datepicker: 30,
  dropdown: 30,
  loader: 40,
  toast: 50,
);

$primary: $atlantis !default;
$secondary: $white !default;
$success: $atlantis !default;
$error: $red !default;
$danger: $appleBlossom !default;
$warning: $supernova !default;
$focusDisabled: sicredi-focus-disabled;

/* stylelint-disable */
:export {
  error: $error;
  danger: $danger;
  success: $success;
  warning: $warning;
  primary: $primary;
  baseline: $baseline;
  secondary: $secondary;
  borderRadius: $borderRadius;
  focusDisabled: $focusDisabled;
}
