@import '../../global.scss';
@import '../../common/StyleVariables/variables.scss';

.banner-container {
  .banner {
    font-family: $fontFamilyBase;
    position: fixed;
    bottom: 2%;
    left: 0;
    right: 0;
    z-index: 99999;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    padding: $baseline * 4;
    border-radius: $baseline;
    background-color: $white;
    margin: 0 30px;
    box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);

    .cookies-paragraph {
      line-height: normal;

      .cookies-a {
        text-decoration: none;
        text-decoration-line: none;
        color: $atlantis;
      }
    }

    .buttons-content {
      margin-left: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $baseline;
      align-items: flex-start;
      justify-content: flex-end;

      .management-cookies-button,
      .all-cookies-button {
        font-family: $fontFamilyBase;
      }

      .management-cookies-button:hover,
      .all-cookies-button:hover,
      .management-cookies-button:focus,
      .all-cookies-button:focus {
        background: $atlantis !important;
        color: $white;
        border: $atlantis;
      }
    }
  }

  @media screen and (min-width: 1725px) {
    .banner {
      align-items: center;
    }
  }

  .cover {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 9999;
  }

  .cover-hidden {
    visibility: hidden;
  }

  .banner-hidden {
    visibility: hidden;
  }
}

@media only screen and (min-width: 805px) {
  .banner-container {
    .banner {
      .p-content {
        text-align: justify;
        padding-right: $baseline * 4;
      }

      .buttons-content {
        align-items: initial;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner-container {
    .banner {
      .p-content {
        text-align: justify;
        padding-right: $baseline * 4;
        align-items: flex-start;

        .cookies-paragraph {
          font-size: $typographySizeSmall;
        }
      }

      .buttons-content {
        margin-right: auto;
        column-gap: $baseline;
        justify-content: space-between;
        align-items: flex-start;

        .management-cookies-button,
        .all-cookies-button {
          font-size: $typographySizeSmall;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .banner-container {
    .banner {
      display: grid;
      grid-template-columns: 1fr;
      .p-content {
        text-align: justify;
        padding-right: 0;
      }

      .buttons-content {
        padding-top: $baseline * 2;
        margin-right: auto;
        column-gap: $baseline;
        row-gap: 10px;
        justify-content: space-between;
        width: 100%;

        .management-cookies-button,
        .all-cookies-button {
          font-size: $typographySizeSmall;
        }
      }
    }
  }
}

@media screen and (max-width: 412px) {
  .banner-container {
    .banner {
      .buttons-content {
        .management-cookies-button,
        .all-cookies-button {
          font-size: $typographySizeXSmall;
        }
      }
    }

    @media screen and (max-width: 350px) {
      .p-content {
        text-align: left;
      }
      .buttons-content {
        .management-cookies-button,
        .all-cookies-button {
          grid-column: 1/3;
        }
      }
    }
  }
}
